import Util from "@/utils/utils";
import { getList, getExport } from "./api";
import mySortBar from "./components/sortBar";
import {env} from "@/common/constant";
export default {
  name: "terminalInspectionRecord",
  components: {
    mySortBar
  },
  data() {

    return {
      sortList: [
        //0默认 1降 2升序
        { name: "创建时间", sortStatus: 1, sortName: "desc" },
      ],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/engineeringReport",
          name: "工程报备",
          title: "工程报备"
        }
      ],
      hideOrShowSize: true,
      columns: [
        {
          label: "意向单编码",
          prop: "intentionCode",
          width: 250,
        },
        {
          label: "营销中心",
          prop: "tradeCompany",
          width: 250,
        },
        {
          label: "使用单位",
          prop: "orderName",
          width: 250,
        },
        {
          label: "使用单位地址",
          prop: "orderNameAdress",
          width: 250,
        },
        {
          label: "工程一级分类",
          prop: "channelTypeName",
          width: 250,
        },
        {
          label: "工程二级分类",
          prop: "channel2TypeName",
          width: 250,
        },
        {
          label: "营销员",
          prop: "marketerName",
          width: 250,
        },
        {
          label: "是否跨营销中心",
          prop: "isCrossMarket",
          width: 250,
        },
        {
          label: "所跨营销中心",
          prop: "crossMarket",
          width: 250,
        },
        {
          label: '物料组',
          prop: 'matklName',
          width: 250,
        },
        {
          label: '预计规模（元）',
          prop: 'expectPrice',
          width: 250,
        },
        {
          label: '预计数量（台）',
          prop: 'expectNum',
          width: 250,
        },
        {
          label: '是否招投标',
          prop: 'isBid',
          width: 250,
        },
        {
          label: '是否形象工程',
          prop: 'isImage',
          width: 250,
        },
        {
          label: '使用单位联系人',
          prop: 'acontacts',
          width: 250,
        },
        {
          label: '使用单位联系电话',
          prop: 'atel',
          width: 250,
        },
        {
          label: '承包单位联系人',
          prop: 'linkPerson',
          width: 250,
        },
        {
          label: '承包单位联系电话',
          prop: 'tel',
          width: 250,
        },
        // {
        //   label: '是否存在特殊需求',
        //   prop: 'isSpecialTxt',
        //   width: 250,
        // },
        // {
        //   label: '特殊需求',
        //   prop: 'attachmentIds',
        //   width: 250,
        //   slot: "attachment",
        // },
        {
          label: "操作",
          slot: "btnClick",
          width: 150,
          fixed: 'right',

        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        sortRule:'desc',
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,

      tableLoading: false,
      selectedRowKeys: [],
      selectRow: []
    };
  },

  mounted() {
    // this.tablelist();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.ssearchList = {
        ...this.ssearchList,
        sortRule: sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc",
      };
      this.pager = {
        ...this.pager,
        pageNo: 1
      }
      this.tablelist();

    },
    fun_date() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },

    // 导出
    exportClick() {
      let data = {
        ...this.ssearchList
      }

      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "工程提报.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },

    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.tablelist();
    },
    searchFun(arg) {
      this.pager.pageNo = 1
      this.ssearchList = {
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    // 获取列表数据
    tablelist() {
      let data = {
         page : this.pager.pageNo,
         pageSize : this.pager.pageSize,
        ...this.ssearchList,


      }
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.code == 0) {
          if (res.data.data.content && res.data.data.content.length > 0) {
            res.data.data.content.forEach(item => {
              item.orderNameAdress = `${item.provinceName?item.provinceName:''}${item.cityName ?item.cityName:''}${item.districtName?item.districtName:''}${item.townName?item.townName:''}${item.addressAll?item.addressAll:''}`
            })
            this.tableData = res.data.data.content;
            this.searchNumber = res.data.data.totalRows;
            this.pager.count = res.data.data.totalRows;
          } else {
            this.tableData = []
          }
          this.tableLoading = false
        } else {
          this.tableData = []
          this.searchNumber = 0
          this.pager.count = 0
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }
      }).catch(error=>{
        this.tableData = []
        this.searchNumber = 0

        this.tableLoading = false
      });
    },
    // 查看流程
    toDetail(row) {
      let id =  row.processId
      if (env == "dev" || env == "test" || env == "uat") {
        // 测试
        window.open('http://xtwdev.clouddev.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      } else {
        // 正式
        window.open('https://xtw.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      }
    },
    // 查看特殊需求
    openView(row){
      window.open(row.attachmentIds, "_blank");
    },

  }
};
