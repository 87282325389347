import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
        url:  '/engineeringReport/getEngineeringReportList.nd',
        method: 'post',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data
    })
}

// 根据id修改
export function changeB2bActivityById(data) {
    return http({
        url: '/b2bMarketActivity/changeB2bActivityById.nd',
        method: 'post',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data
    })
}

// 获取详情
export function getDeatil(data) {
    return http({
        url: 'cts/ctsApi.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}


export function getExport(data) {
    return http({
        url: '/engineeringReport/exportResultList.nd',
        method: 'post',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data:data,
        responseType: 'blob'
    })
}





// export function getExport(data) {
//     return http({
//         url: 'cts/ctsApi.nd',
//         method: 'post',
//         data,
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//         responseType: 'blob'
//     })
// }